import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  // get prep item cost by id
  async fetchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.itemCharge.Controller.findById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get charges by item id
  async fetchAllByItemId(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.itemCharge.Controller.findAllByItemId(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async create(itemId, data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.itemCharge.Controller.create(itemId), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async delete(id) {
    const result = await axios.delete(endpoints.itemCharge.Controller.delete(id))
    return result
  },

}
